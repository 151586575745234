import React, { Fragment, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, useHistory, Link, useLocation } from 'react-router-dom'
import { observer, Provider as StoreProvider } from 'mobx-react'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import userflow from 'userflow.js'

import 'libs/cabinet' // order import is important (I18n should be always on the top)
import 'libs/common/analytics'

import { setCookies, getCookies } from 'libs/common/cookies'

import '../../../styles/cabinet.scss'

import { initSentry } from 'libs/common/sentry'
import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'
import FunctionalLimitationsModal from 'shared/components/FunctionalLimitationsModal'
import { Page } from 'shared/components/side-menu/Page'
import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'
import ConfirmationModal from 'ui/confirmation-modal/ConfirmationModal'

import {
  ELO_GTM_CODES,
  ELOPAGE_COOKIEBOT_CONFIG,
  USERFLOW_INIT_TOKEN,
  USERFLOW_LOCALE_CODE,
} from 'constants/general.constants'
import { THEME_SHARING_PARAMS } from 'constants/themes.constants'
import { SHARABLE_FORM, SHARABLE_TYPE } from 'constants/sharing.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { SALES_TEAM_OPTION_KEY } from 'constants/options.constants'
import { SELLER_POPUP_VIEW_EVENT, SELLER_POPUP_CLICK_EVENT } from 'constants/GTMDataLayer.constants'

import { getElopageConfig } from 'utils/elopageConfig.utils'
import { profile } from 'utils/profileHelper.utils'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { handleInitCabinetFetching, handleProfileFetchFailure } from 'utils/requests.utils'
import { getParamsFromUrl } from 'utils/helpers.utils'
import { injectFontList } from 'utils/fonts.utils'
import { getProfileFullName } from 'utils/user.utils'
import { appendScript } from 'utils/dom.utils'
import { getCookiebotScript } from 'utils/cookiesConsents.utils'
import { isThemePreviewRoute } from 'utils/themes.utils'
import { addToDataLayer } from 'utils/GTMDataLayer.utils'

import { LoadingMask, LoadingSpinner, I18nProvider } from '@elo-kit/components'
import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'

import { MetaTags } from 'shared/components/MetaTags'
import { isWindowEnv } from 'utils/env.utils'
import { isTrialPeriodEnd } from 'utils/seller.utils'

import { supportRequest } from 'shared/api/users.api'

import { ToastContainer } from 'react-toastify'
import { CABINET_STATSIG_CLIENT_KEYS, marketingWebHosts } from 'libs/configs'
import { UserSessionProvider } from 'shared/context/user-session-provider'
import { ExperimentsAndFeatureGatesProvider } from 'shared/context/experiments-and-feature-gates-provider'
import Routes from './routes'

import LicenseKeyModal from './screens/sharings/LicenseKeyModal'
import MultipleItemsSharingSuccessModal from './screens/multi-items-sharing/MultipleItemsSharingSuccessModal'

import { useCabinetStore } from './hooks/use-cabinet-stores'

initSentry()

const TRIAL_PERIOD_MODAL_KEY = 'trial_period_modal'

const detectProfileType = () => {
  let profileType = getCookies('cabinet_profile_type')
  profileType =
    [ACTIVE_PROFILES.seller, ACTIVE_PROFILES.teamMember].indexOf(profileType) >= 0
      ? profileType
      : ACTIVE_PROFILES.seller
  profile.setProfileType(profileType)
}

detectProfileType()

const getSellerDetailsForDataLayer = (user) => ({
  s_id: user.id,
  pl_id: user.planId,
  u_id: user.userId,
})

const CabinetMetaTags = ({ sellerStore }) => {
  const history = useHistory()
  return <MetaTags pathname={history.location.pathname} sellerStore={sellerStore} />
}

const PageSideMenu = ({
  profileStore,
  hideBurgerMenu,
  sellerStore,
  profileType,
  userStore,
  currenciesStore,
  publisherStore,
  eloPublisherStore,
  teamMemberStore,
  salesTeamMemberStore,
  payerStore,
  salesTeamsStore,
  paymentSettingStore,
  clearCacheStore,
}) => {
  const { pathname } = useLocation()

  return (
    <Page.SideMenu
      sellerStore={sellerStore}
      profileStore={profileStore}
      profileType={profileType}
      hideBurgerMenu={hideBurgerMenu}
      pathname={pathname}
      LinkComponent={Link}
      userStore={userStore}
      publisherStore={publisherStore}
      eloPublisherStore={eloPublisherStore}
      teamMemberStore={teamMemberStore}
      salesTeamMemberStore={salesTeamMemberStore}
      payerStore={payerStore}
      currenciesStore={currenciesStore}
      salesTeamsStore={salesTeamsStore}
      paymentSettingStore={paymentSettingStore}
      clearCacheStore={clearCacheStore}
    />
  )
}

const CabinetApp = observer(function App() {
  const cabinetStores = useCabinetStore()
  const {
    userStore,
    teamMemberStore,
    sellerStore,
    currenciesStore,
    sellerFontsStore,
    sharingsStore,
    salesTeamsStore,
    trackingLogsStore,
    courseViewsStore,
    publisherStore,
    eloPublisherStore,
    payerStore,
    dealsStore,
    paymentSettingStore,
    clearCacheStore,
    experimentsStore,
  } = cabinetStores
  const trialModal = !getCookies(TRIAL_PERIOD_MODAL_KEY)
  const [showTrialModal, setShowTrialModal] = useState(trialModal)

  const toggleTrialModal = () => {
    setShowTrialModal(false)
    addToDataLayer({
      ...SELLER_POPUP_CLICK_EVENT,
      object: 'overview',
      event_name: 'overview_upgradePopupClose',
    })
    setCookies(TRIAL_PERIOD_MODAL_KEY, true)
  }

  const fireGtmRegCompleted = () => {
    const { item: seller } = sellerStore
    const env = getElopageConfig('env')
    const tagManagerAttrs = {
      gtmId: ELO_GTM_CODES[env],
      dataLayer: {
        id: `f${(+new Date()).toString(16)}`,
        event: 'Client_SignUp',
        fullName: getProfileFullName(seller.userProfile),
        username: seller.username,
        email: userStore.item.email,
      },
    }
    const data = {
      info: tagManagerAttrs,
      form: 'elo_registration_tracking',
      trackingCodeForm: 'google_tag_manager',
    }
    trackingLogsStore.createItem(data).then(() => TagManager.initialize(tagManagerAttrs))
    sessionStorage.removeItem('new_seller_reg_completed')
  }

  const isAllowed = (key) => {
    const { permissionsKeys } = teamMemberStore.selectedSellerInvite || {}
    const profileTypeCondition = profile.profileType === ACTIVE_PROFILES.seller
    const permissionsCondition = (permissionsKeys || []).length > 0 && permissionsKeys.includes(key)
    return profileTypeCondition || permissionsCondition
  }

  const handleTrialModalSubmit = () => {
    addToDataLayer({
      ...SELLER_POPUP_CLICK_EVENT,
      object: 'overview',
      event_name: 'overview_upgradePopup',
    })
    window.open(`https://${marketingWebHosts.production}/preise`, '_blank')
    toggleTrialModal()
  }

  let sellerHasAtLeastOnePaidDeal = false
  const env = getElopageConfig('env')
  const fetchSellerDeals = async () => {
    await dealsStore.fetchList({ state: 'closed' })
    sellerHasAtLeastOnePaidDeal = dealsStore.pagination.total >= 1
  }

  const onChangeSupportId = async () => {
    const userFlowState = userflow.getResourceCenterState()
    if (userFlowState.isOpen && !onChangeSupportId.token) {
      const response = await supportRequest({ profile_type: 'seller' })
      if (response?.success) {
        onChangeSupportId.token = response.data.token
        userflow.updateUser({
          support_token_id: response.data.token,
        })
      }
    }
    if (!userFlowState.isOpen) {
      onChangeSupportId.token = ''
    }
  }

  const fetchUserFlowData = async () => {
    userflow.init(USERFLOW_INIT_TOKEN[env])

    await userflow.identify(userStore.item.id, {
      name: userStore.item.sellerUsername,
      email: userStore.item.email,
      phone: sellerStore.item.userProfile?.phone,
      user_first_name: sellerStore.item.userProfile?.firstName,
      user_last_name: sellerStore.item.userProfile?.lastName,
      signed_up_at: sellerStore.item.createdAt,
      salesforce_contact_id: sellerStore.item.salesforce?.contactId,
      locale_code: USERFLOW_LOCALE_CODE[userStore.item.locale],
      seller_groups: userStore.item.seller_username,
      first_time_signed_in_user: userStore.item.signInCount === 1,
      seller_has_one_paid_deal: sellerHasAtLeastOnePaidDeal,
      support_token_id: '',
    })

    await userflow.group(sellerStore.item.id, {
      seller_id: sellerStore.item.id,
      seller_account_name: sellerStore.item.userName,
      seller_signed_up_at_date: sellerStore.item.createdAt,
      signed_up_at: sellerStore.item.createdAt,
      locale_code: USERFLOW_LOCALE_CODE[userStore.item.locale],
      sfdc_account_id: sellerStore.item.salesforce?.accountId,
    })
  }

  useEffect(() => {
    const fetchSellerFonts = async () => {
      const { success } = await sellerFontsStore.fetchFullList()

      if (success) {
        injectFontList(sellerFontsStore.list)
      }
    }

    const customHandlingResp = async (profileResp) => {
      const isTeamMemberProfile = profile.profileType === ACTIVE_PROFILES.teamMember
      if (profileResp.success) {
        if (isTeamMemberProfile) {
          teamMemberStore.setSelectedSellerUsername()
        }
      } else {
        profile.setProfileType(ACTIVE_PROFILES.seller)
        setCookies('cabinet_profile_type', ACTIVE_PROFILES.seller)
      }
      await fetchSellerDeals()

      sharingsStore.handleSharingForm()
      sellerStore.setExpands([
        'selected_answers',
        'user_profile',
        'revenue_by_currencies',
        'active_payment_setting',
        'salesforce',
      ])
      sellerStore.setCounts(['products'])
      const { success } = await sellerStore.fetchItem()

      await currenciesStore.fetchList()

      const isSalesTeamAppActive = sellerStore.isAppActive(SALES_TEAM_OPTION_KEY)

      if (isSalesTeamAppActive) {
        await salesTeamsStore.fetchList()
      }

      if (isTeamMemberProfile) {
        await fetchSellerFonts()
      }

      if (!success) {
        return handleProfileFetchFailure(userStore)
      }

      if (!isEmpty(sellerStore.item)) {
        const details = getSellerDetailsForDataLayer(sellerStore.item)
        addToDataLayer({ event: 'loggedIn', ...details })
      }

      const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage

      if (sessionStorage && sessionStorage.getItem('new_seller_reg_completed')) {
        fireGtmRegCompleted()
      }

      if (sellerStore.item.showTrialEnding && showTrialModal) {
        addToDataLayer({
          ...SELLER_POPUP_VIEW_EVENT,
          object: 'overview',
          event_name: 'overview_upgradePopupView',
        })
      }

      fetchUserFlowData()

      userflow.on('resourceCenterChanged', onChangeSupportId)

      return () => {
        userflow.off('resourceCenterChanged', onChangeSupportId)
      }
    }

    handleInitCabinetFetching(userStore, teamMemberStore, customHandlingResp)
    if (profile.profileType === ACTIVE_PROFILES.seller) {
      fetchSellerFonts()
    }
    const { src, attributes } = getCookiebotScript(
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotId,
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotMode
    )

    // need settings 'cause side menu items relay on them
    paymentSettingStore.fetchData()

    appendScript(src, attributes, false)
  }, [])

  const {
    sharingConfirmationModalOpened,
    sharingSubmitEnabled,
    successSharingModalOpened,
    isSharingLoading,
    handleThemeSharing,
    handleCancelSharingConfirmation,
    toggleSharingConfirmationModalOpened,
    toggleSuccessSharingModal,
    handleSharingCancel,
    item: sharing = {},
  } = sharingsStore
  const { mainSideMenuOpen } = courseViewsStore

  const isThemePreview = isThemePreviewRoute()
  const isAffiliateMarketplace = document.location.pathname.includes('affiliate_marketplace')
  const contentPageClasses = classNames('content-page', {
    'content-page--full-screen': !mainSideMenuOpen || isThemePreview || isAffiliateMarketplace,
  })
  const isSeller = profile.profileType === ACTIVE_PROFILES.seller
  const profileStore = isSeller ? sellerStore : teamMemberStore

  const isServiceHub = document.location.pathname.includes('service_hub')
  const isPrivate = sharing?.form === SHARABLE_FORM.private
  const urlParams = getParamsFromUrl(THEME_SHARING_PARAMS)
  const sharingToken = urlParams.shared_theme_token || getCookies('shared_theme_token')
  const sharableType = urlParams.shared_theme_type || getCookies('shared_theme_type')
  const showLicenseKeyModal = sharingToken && sharableType
  const sharingWarningMessage = sharingSubmitEnabled
    ? I18n.t('react.cabinet.themes.apply_theme_warning')
    : I18n.t('react.cabinet.themes.apply_theme_loading_warning')
  const isMultiSharing = sharableType === SHARABLE_TYPE.multiItemsSharing
  const confirmationModalTexts = isMultiSharing
    ? {
        title: I18n.t('react.cabinet.themes.multiple_items_confirmation.title'),
        message: I18n.t('react.cabinet.themes.multiple_items_confirmation.message'),
      }
    : {
        title: I18n.t('react.cabinet.themes.share_confirmation.title'),
        message: I18n.t('react.cabinet.themes.share_confirmation.message'),
      }

  return (
    <UserSessionProvider cookieName={'seller_session_id'}>
      <ExperimentsAndFeatureGatesProvider
        clientKey={CABINET_STATSIG_CLIENT_KEYS[process.env.ENV]}
        experimentsStore={experimentsStore}
      >
        <I18nProvider>
          <StoreProvider {...cabinetStores}>
            <Router>
              <Fragment>
                <CabinetMetaTags sellerStore={sellerStore} />
                {!sellerStore.item.id ? (
                  <LoadingMask screenHeight />
                ) : (
                  <Page>
                    {mainSideMenuOpen && !isThemePreview && !isServiceHub && !isAffiliateMarketplace && (
                      <PageSideMenu
                        sellerStore={sellerStore}
                        profileStore={profileStore}
                        profileType={profile.profileType}
                        hideBurgerMenu={courseViewsStore.inited}
                        currenciesStore={currenciesStore}
                        userStore={userStore}
                        publisherStore={publisherStore}
                        eloPublisherStore={eloPublisherStore}
                        teamMemberStore={teamMemberStore}
                        payerStore={payerStore}
                        salesTeamsStore={salesTeamsStore}
                        paymentSettingStore={paymentSettingStore}
                        clearCacheStore={clearCacheStore}
                      />
                    )}

                    <Page.Content classNames={contentPageClasses}>
                      <Routes isAllowed={isAllowed} isTrialPeriodEnd={isTrialPeriodEnd(sellerStore.item.planId)} />
                      <FunctionalLimitationsModal />
                    </Page.Content>

                    <AdminMode userEmail={userStore.item.email} />
                    <ApiBranch />
                    {isPrivate && isSeller && showLicenseKeyModal && (
                      <LicenseKeyModal
                        submit={handleThemeSharing}
                        cancel={handleSharingCancel}
                        sharingSubmitEnabled={sharingSubmitEnabled}
                      />
                    )}
                    {!isPrivate && isSeller && sharingConfirmationModalOpened && (
                      <ConfirmationModal
                        isOpen={sharingConfirmationModalOpened}
                        submit={handleThemeSharing}
                        submitDisabled={!sharingSubmitEnabled}
                        onClose={handleCancelSharingConfirmation}
                        onCancel={handleCancelSharingConfirmation}
                        cancel={sharingSubmitEnabled}
                        close={sharingSubmitEnabled}
                        toggle={toggleSharingConfirmationModalOpened}
                        title={confirmationModalTexts.title}
                        submitButtonChild={I18n.t('react.shared.button.apply')}
                        message={confirmationModalTexts.message}
                        warningMessage={!isMultiSharing && sharingWarningMessage}
                        titleIcon={false}
                        headerIcon={
                          <i
                            className='
                            fas fa-arrow-circle-down
                            elo-modal__header-icon elo-modal__header-icon--import
                          '
                          />
                        }
                        onlySubmit
                      >
                        {isSharingLoading && <LoadingSpinner />}
                      </ConfirmationModal>
                    )}
                    {successSharingModalOpened && (
                      <MultipleItemsSharingSuccessModal
                        toggle={toggleSuccessSharingModal}
                        sharingItems={sharing?.sharingItems}
                      />
                    )}
                    {sellerStore.item.showTrialEnding && showTrialModal && (
                      <EloModal isOpen onClose={toggleTrialModal} isOverlayFixed>
                        <EloModalHeader>{I18n.t('react.cabinet.trial_modal.header_title')}</EloModalHeader>
                        <EloModalBody>
                          <div>{I18n.t('react.cabinet.trial_modal.trial_info_message')}</div>
                          <div>{I18n.t('react.cabinet.trial_modal.trial_upgrade_info')}</div>
                        </EloModalBody>
                        <EloModalFooter className='elo-ui-modal__footer--end'>
                          <EloButton variant='secondary' onClick={toggleTrialModal}>
                            {I18n.t('react.shared.button.close')}
                          </EloButton>
                          <EloButton variant='highlight' onClick={handleTrialModalSubmit}>
                            {I18n.t('react.shared.button.upgrade_now')}
                          </EloButton>
                        </EloModalFooter>
                      </EloModal>
                    )}
                  </Page>
                )}
              </Fragment>
            </Router>

            <ToastContainer />
          </StoreProvider>
        </I18nProvider>
      </ExperimentsAndFeatureGatesProvider>
    </UserSessionProvider>
  )
})

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <CabinetApp />
  </ErrorBoundary>
  // </React.StrictMode>
)
