import React, { createContext } from 'react'

import { getCookies, setCookies } from 'libs/common/cookies'
import { createFingerPrint } from 'utils/fingerprint.utils'

const maxAge = 60 * 60 * 24 * 365 // 365 days

export const UserSessionContext = createContext({ userSessionId: '' })

export const UserSessionProvider: React.FC<{ cookieName: string; children: React.ReactNode }> = ({
  children,
  cookieName,
}) => {
  let userSessionId = getCookies(cookieName)

  if (!userSessionId) {
    userSessionId = createFingerPrint()
    setCookies(cookieName, userSessionId, { maxAge })
  }

  return <UserSessionContext.Provider value={{ userSessionId }}>{children}</UserSessionContext.Provider>
}
