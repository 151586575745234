import React, { lazy, Suspense, Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import LoadingFallback from 'ui/loading-fallback/LoadingFallback'
import { cancelPendingRequests } from 'utils/request-cancelation.utils'
import { DUNNING_KEY } from 'constants/options.constants'

const ExpiredTrial = lazy(() => import('./screens/expired-trial/ExpiredTrial'))
const BlockedPage = lazy(() => import('shared/screens/BlockedPage'))
const IframesResolver = lazy(() => import('shared/screens/IframesResolver'))
const ReloadPage = lazy(() => import('shared/components/ReloadPage'))
const Error404 = lazy(() => import('shared/components/Error404'))

const EditInvoice = lazy(() => import('./screens/edit-invoice/InvoiceEdit'))
const PreviewContent = lazy(() => import('./screens/page-builder/page-preview/PreviewContent'))
const BankAccountsList = lazy(() => import('./screens/bank-accounts/BankAccountsList'))
const EmbeddableItemsEdit = lazy(() => import('./screens/embeddable-items/EmbeddableItemsEdit'))
const EmbeddableItemsCreate = lazy(() => import('./screens/embeddable-items/EmbeddableItemsCreate'))
const EmbeddableItems = lazy(() => import('./screens/embeddable-items/EmbeddableItemsList'))
const ProfileForm = lazy(() => import('./screens/profile/ProfileForm'))
const AffiliateMarketplaceProductDetails = lazy(
  () => import('./screens/affiliate-marketplace/product-details/AffiliateMarketplaceProductDetails')
)
const AffiliateMarketplace = lazy(() => import('./screens/affiliate-marketplace/AffiliateMarketplace'))
const CoursePreview = lazy(() => import('./screens/CoursePreview'))
const PageNotFound = lazy(() => import('./screens/PageNotFound'))
const Notifications = lazy(() => import('./screens/mobile-app/Notifications'))
const MobileApp = lazy(() => import('./screens/mobile-app/MobileApp'))
const IapProducts = lazy(() => import('./screens/mobile-app/IapProducts'))
const CommunitiesList = lazy(() => import('./screens/community/CommunitiesList'))
const CommunityFeed = lazy(() => import('./screens/community/CommunityFeed'))
const CommunitiesFeedPosts = lazy(() => import('./screens/community/CommunitiesFeedPosts'))
const CommunitiesPostsModeration = lazy(() => import('./screens/community/CommunitiesPostsModeration'))
const FunnelsList = lazy(() => import('./screens/funnels/FunnelsList'))
const FunnelItem = lazy(() => import('./screens/funnels/FunnelItem'))
const VatSettings = lazy(() => import('./screens/vat-settings/VatSettings'))
const ProductForm = lazy(() => import('./screens/products/ProductForm'))
const ProductCreate = lazy(() => import('./screens/products/productCreate/ProductCreate'))
const ProductsList = lazy(() => import('./screens/products/productsList/ProductsList'))
const ProductLinks = lazy(() => import('./screens/products/productLinks/ProductLinks'))
const Cashout = lazy(() => import('./screens/cashout/Cashout'))
const CustomFieldsList = lazy(() => import('./screens/opt-ins/CustomFieldsList'))
const TrackingCodesList = lazy(() => import('./screens/tracking-codes/TrackingCodesList'))
const TrackingCodesForm = lazy(() => import('./screens/tracking-codes/TrackingCodesForm'))
const Billing = lazy(() => import('./screens/billing'))
const Coupons = lazy(() => import('./screens/coupons/Coupons'))
const CouponForm = lazy(() => import('./screens/coupons/CouponForm'))
const Privacy = lazy(() => import('./screens/privacy-policy/PrivacyPolicy'))
const ElopagePolicyEdit = lazy(() => import('./screens/privacy-policy/ElopagePolicyEdit'))
const LogEmails = lazy(() => import('./screens/logs/LogsEmails'))
const LogsContacts = lazy(() => import('./screens/logs/LogsContacts'))
const LogsWebhooks = lazy(() => import('./screens/logs/LogsWebhooks'))
const LogsTracking = lazy(() => import('./screens/logs/LogsTracking'))
const LogsCsv = lazy(() => import('./screens/logs/LogsCsv'))
const LogsPushNotification = lazy(() => import('./screens/logs/LogsPushNotification'))
const TransferItem = lazy(() => import('./screens/transfers/TransfersItem'))
const TransferList = lazy(() => import('./screens/transfers/TransfersList'))
const VibanTransfersList = lazy(() => import('./screens/transfers/VibanTransfersList'))
const AffiliateRedirectionsList = lazy(() => import('./screens/affiliate-redirections/AffiliateRedirections'))
const AffiliateRedirectionNew = lazy(() => import('./screens/affiliate-redirections/AffiliateRedirectionForm'))
const AffiliateRedirectionEdit = lazy(() => import('./screens/affiliate-redirections/AffiliateRedirectionForm'))
const FileLibrary = lazy(() => import('./screens/file-library/FileLibrary'))
const FontsList = lazy(() => import('./screens/fonts/FontsList'))
const VideoDetails = lazy(() => import('./screens/videos/VideoDetails'))
const InvoicesReversalsList = lazy(() => import('./screens/invoicesReversalsList/InvoicesReversalsList'))
const InvoicesItem = lazy(() => import('./screens/invoices/InvoiceDetails'))
const InvoiceCreate = lazy(() => import('./screens/invoices/InvoiceCreate'))
const CreditMemosList = lazy(() => import('./screens/credit-memos/CreditMemosList'))
const PaymentTicketsOverview = lazy(() => import('./screens/payment-tickets/PaymentTicketsOverview'))
const PaymentTicketsDetails = lazy(() => import('./screens/payment-tickets/PaymentTicketsDetails'))
const PaymentTicketsItem = lazy(() => import('./screens/payment-tickets/PaymentTicketsItem'))
const WebhookEndpoints = lazy(() => import('./screens/webhook-endpoints/WebhookEndpoints'))
const WebhookEndpointsCreate = lazy(() => import('./screens/webhook-endpoints/WebhookEndpointsForm'))
const WebhookEndpointsEdit = lazy(() => import('./screens/webhook-endpoints/WebhookEndpointsForm'))
const PaymentPageTemplatesList = lazy(() => import('./screens/payment-page-templates/PaymentPageTemplatesList'))
const PaymentPageTemplatesForm = lazy(() => import('./screens/payment-page-templates/PaymentPageTemplatesForm'))
const ProductGroups = lazy(() => import('./screens/product-groups/ProductGroups'))
const ProductGroupsForm = lazy(() => import('./screens/product-groups/ProductGroupsForm'))
const DomainSettings = lazy(() => import('./screens/domain'))
const SubdomainPage = lazy(() => import('./screens/subdomain'))
const CustomDomain = lazy(() => import('./screens/custom-domain'))
const TeamProjects = lazy(() => import('./screens/team-projects/TeamProjects'))
const TeamMemberRoleEdit = lazy(() => import('./screens/team-projects/roles/TeamMemberRoleEdit'))
const OrdersList = lazy(() => import('./screens/orders/OrdersList'))
const OrdersItem = lazy(() => import('./screens/orders/OrdersItem'))
const CoursesOverview = lazy(() => import('./screens/courses/CoursesOverview'))
const QuizList = lazy(() => import('./screens/courses/quiz-answers/QuizList'))
const QuizDetails = lazy(() => import('./screens/courses/quiz-answers/QuizDetails'))
const CourseSessionsList = lazy(() => import('./screens/courses/course-sessions/CourseSessionsList'))
const CourseSessionsItem = lazy(() => import('./screens/courses/course-sessions/CourseSessionsItem'))
const LessonStatusesList = lazy(() => import('./screens/courses/LessonStatusesList'))
const PayersList = lazy(() => import('./screens/payers/PayersList'))
const PayersItem = lazy(() => import('./screens/payers/PayersItem'))
const AffiliatePrograms = lazy(() => import('./screens/affiliate-programs/AffiliatePrograms'))
const AffiliateProgramsForm = lazy(() => import('./screens/affiliate-programs/AffiliateProgramsForm'))
const AffiliateNodes = lazy(() => import('./screens/affiliate-programs/affiliate-nodes/AffiliateNodes'))
const AffiliateNodeCreate = lazy(() => import('./screens/affiliate-programs/affiliate-nodes/AffiliateNodeCreate'))
const AffiliateProgramsStatistics = lazy(
  () => import('./screens/affiliate-programs/statistic/AffiliateProgramsStatistics')
)
const PayoutsList = lazy(() => import('./screens/payouts/payouts/PayoutsList'))
const PayoutsSchedule = lazy(() => import('./screens/payouts/payouts-balance/PayoutsSchedule'))
const PayoutsSetting = lazy(() => import('./screens/payouts/payouts-setting/PayoutsSetting'))
const MembershipThemesList = lazy(() => import('./screens/membership-themes/MembershipThemesList'))
const MembershipThemesForm = lazy(() => import('./screens/membership-themes/MembershipThemesForm'))
const ShopThemesList = lazy(() => import('./screens/shop-themes/ShopThemesList'))
const ShopThemesForm = lazy(() => import('./screens/shop-themes/ShopThemesForm'))
const CourseThemesList = lazy(() => import('./screens/course-themes/CourseThemesList'))
const CourseThemesForm = lazy(() => import('./screens/course-themes/CourseThemesForm'))
const IntegrationSettings = lazy(() => import('./screens/integration/IntegrationSettings'))
const PricingPlansList = lazy(() => import('./screens/pricing-plans/PricingPlansList'))
const PricingPlansForm = lazy(() => import('./screens/pricing-plans/PricingPlansForm'))
const Imprint = lazy(() => import('./screens/imprint/Imprint'))
const Terms = lazy(() => import('./screens/terms/Terms'))
const Overview = lazy(() => import('./screens/statistics/Overview'))
const Analysis = lazy(() => import('./screens/statistics/Analysis'))
const Analysis3 = lazy(() => import('./screens/statistics/Analysis3'))
const FinancialReportsList = lazy(() => import('./screens/financial-reports/FinancialReports'))
const InvoiceSummariesList = lazy(() => import('./screens/invoice-summaries/InvoiceSummaries'))
const PaymentMethods = lazy(() => import('./screens/payment-methods/PaymentMethods'))
const LicenseKeysOverview = lazy(() => import('./screens/license-keys/LicenseKeysOverview'))
const LicenseKeysDetails = lazy(() => import('./screens/license-keys/LicenseKeysDetails'))
const CertificatesOverview = lazy(() => import('./screens/certificates/CertificatesOverview'))
const CertificatesDetails = lazy(() => import('./screens/certificates/CertificatesDetails'))
const AppThemesList = lazy(() => import('./screens/app-themes/AppThemesList'))
const AppThemesForm = lazy(() => import('./screens/app-themes/AppThemesForm'))
const FinishRegistration = lazy(() => import('./screens/finish-registration/FinishRegistration'))
const InvoiceSetup = lazy(() => import('./screens/invoice-setup/InvoiceSetup'))
const OrderRatesList = lazy(() => import('./screens/order-rates/OrderRatesList'))
const OrderRatesItem = lazy(() => import('./screens/order-rates/OrderRatesItem'))
const NotificationSettings = lazy(() => import('./screens/notifications/NotificationSettings'))
const PaymentSettings = lazy(() => import('./screens/payment-methods/PaymentSettings'))
const CancellationTermsList = lazy(() => import('./screens/cancellation-terms/CancellationTermsList'))
const CancellationTermsForm = lazy(() => import('./screens/cancellation-terms/CancellationTermsFormResolver'))
const EmbeddableItemsPreview = lazy(() => import('./screens/embeddable-items/EmbeddableItemsPreview'))
const AdditionalFeesList = lazy(() => import('./screens/additional-fees/AdditionalFeesList'))
const MembershipSessionsOverview = lazy(() => import('./screens/membership-sessions/MembershipSessionsOverview'))
const MembershipSessionsAccess = lazy(() => import('./screens/membership-sessions/MembershipSessionsAccess'))
const MembershipSessionsItem = lazy(() => import('./screens/membership-sessions/MembershipSessionsItem'))
const CookiesConsents = lazy(() => import('./screens/cookies-consents/CookiesConsents'))
const IntegrationsList = lazy(() => import('./screens/integrations/IntegrationsList'))
const IntegrationsForm = lazy(() => import('./screens/integrations/IntegrationsForm'))
const NeedActionTransfersList = lazy(() => import('./screens/need-action-transfers/NeedActionTransfersList'))
const EmailConfigurations = lazy(() => import('./screens/email-configurations/EmailConfigurations'))
const MobileAppNotificationsItem = lazy(() => import('./screens/mobile-app/MobileAppNotificationsItem'))
const OrderBumpsThemesList = lazy(() => import('./screens/order-bumps/OrderBumpsThemesList'))
const AffiliateLandingsList = lazy(() => import('./screens/affiliate-landings/AffiliateLandingsList'))
const AffiliateLandingsForm = lazy(() => import('./screens/affiliate-landings/AffiliateLandingsForm'))
const ContactFormLeadsList = lazy(() => import('./screens/payers/contact-form-leads/ContactFormLeadsList'))
const OptInAnswersList = lazy(() => import('./screens/opt-in-answers/OptInAnswersList'))
const MultipleItemsSharingList = lazy(() => import('./screens/multi-items-sharing/MultipleItemsSharingList'))
const LiveShoppingList = lazy(() => import('./screens/live-shopping/LiveShoppingList'))
const LiveShoppingForm = lazy(() => import('./screens/live-shopping/LiveShoppingForm'))
const SalesTeam = lazy(() => import('./screens/sales-team/SalesTeam'))
const IncentivePlanList = lazy(() => import('./screens/incentive-plan/IncentivePlanList'))
const ManageSalesTeamMembersList = lazy(() => import('./screens/members/ManageSalesTeamMembersList'))
const DealsList = lazy(() => import('./screens/deals/DealsList'))
const Deal = lazy(() => import('./screens/deals/Deal'))
const DunningList = lazy(() => import('./screens/dunning/DunningList'))
const ClaimStrategySettings = lazy(() => import('./screens/dunning/claim-strategy/ClaimStrategySettings'))
const ServiceHub = lazy(() => import('./screens/service_hub/ServiceHub'))
const ProtectedRoute = ({ isAllowed, defaultAccess, ...props }) =>
  defaultAccess || isAllowed(props) ? <Route {...props} /> : <BlockedPage />

const Routes = ({ isAllowed, isTrialPeriodEnd }) => (
  <Suspense fallback={<LoadingFallback type='cabinet' />}>
    <Switch>
      <ProtectedRoute
        exact
        path='/cabinet'
        component={isTrialPeriodEnd ? ExpiredTrial : Overview}
        isAllowed={() => isAllowed('overview')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/additional_fees/:id?'
        component={isTrialPeriodEnd ? ExpiredTrial : AdditionalFeesList}
        isAllowed={() => isAllowed('additional_fees')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/order_bumps_templates'
        component={isTrialPeriodEnd ? ExpiredTrial : OrderBumpsThemesList}
        isAllowed={() => isAllowed('order_bumps')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/funnels'
        component={isTrialPeriodEnd ? ExpiredTrial : FunnelsList}
        isAllowed={() => isAllowed('funnels')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/funnels/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : FunnelItem}
        isAllowed={() => isAllowed('funnels')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/vat_settings'
        component={isTrialPeriodEnd ? ExpiredTrial : VatSettings}
        isAllowed={() => isAllowed('vat_settings')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductsList}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/new'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductCreate}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/new/bundle'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductCreate}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/new/:form/:category?'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductForm}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductLinks}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductForm}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/:id/course-preview'
        component={isTrialPeriodEnd ? ExpiredTrial : CoursePreview}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/product_groups'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductGroups}
        isAllowed={() => isAllowed('product_groups')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/product_groups/new'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductGroupsForm}
        isAllowed={() => isAllowed('product_groups')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/product_groups/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : ProductGroupsForm}
        isAllowed={() => isAllowed('product_groups')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/cashouts'
        component={isTrialPeriodEnd ? ExpiredTrial : Cashout}
        isAllowed={() => isAllowed('cashouts')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/opt_ins'
        component={isTrialPeriodEnd ? ExpiredTrial : CustomFieldsList}
        isAllowed={() => isAllowed('opt_ins')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/opt_in_answers'
        component={isTrialPeriodEnd ? ExpiredTrial : OptInAnswersList}
        isAllowed={() => isAllowed('opt_in_answers')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/tracking_codes'
        component={isTrialPeriodEnd ? ExpiredTrial : TrackingCodesList}
        isAllowed={() => isAllowed('tracking_codes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/tracking_codes/new'
        component={isTrialPeriodEnd ? ExpiredTrial : TrackingCodesForm}
        isAllowed={() => isAllowed('tracking_codes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/tracking_codes/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : TrackingCodesForm}
        isAllowed={() => isAllowed('tracking_codes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/coupons'
        component={isTrialPeriodEnd ? ExpiredTrial : Coupons}
        isAllowed={() => isAllowed('coupons')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/coupons/new'
        component={isTrialPeriodEnd ? ExpiredTrial : CouponForm}
        isAllowed={() => isAllowed('coupons')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/coupons/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : CouponForm}
        isAllowed={() => isAllowed('coupons')}
      />
      <ProtectedRoute exact path='/cabinet/privacy' component={Privacy} isAllowed={() => isAllowed('privacy')} />
      <ProtectedRoute
        exact
        path='/cabinet/privacy/edit'
        component={ElopagePolicyEdit}
        isAllowed={() => isAllowed('privacy')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/cookies_consent'
        component={CookiesConsents}
        isAllowed={() => isAllowed('privacy')}
      />
      <ProtectedRoute exact path='/cabinet/billing' component={Billing} isAllowed={() => isAllowed('billing')} />
      <ProtectedRoute
        exact
        path='/cabinet/logs/emails'
        component={isTrialPeriodEnd ? ExpiredTrial : LogEmails}
        isAllowed={() => isAllowed('logs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/logs/contacts'
        component={isTrialPeriodEnd ? ExpiredTrial : LogsContacts}
        isAllowed={() => isAllowed('logs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/logs/webhooks'
        component={isTrialPeriodEnd ? ExpiredTrial : LogsWebhooks}
        isAllowed={() => isAllowed('logs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/logs/tracking'
        component={isTrialPeriodEnd ? ExpiredTrial : LogsTracking}
        isAllowed={() => isAllowed('logs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/logs/csv'
        component={isTrialPeriodEnd ? ExpiredTrial : LogsCsv}
        isAllowed={() => isAllowed('logs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/logs/push_notifications'
        component={isTrialPeriodEnd ? ExpiredTrial : LogsPushNotification}
        isAllowed={() => isAllowed('mobile_app')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/transfers/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : TransferItem}
        isAllowed={() => isAllowed('transfers')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/transfers/'
        component={isTrialPeriodEnd ? ExpiredTrial : TransferList}
        isAllowed={() => isAllowed('transfers')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payouts_balance'
        component={isTrialPeriodEnd ? ExpiredTrial : PayoutsSchedule}
        isAllowed={() => isAllowed('payouts_balance')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payouts_settings'
        component={isTrialPeriodEnd ? ExpiredTrial : PayoutsSetting}
        isAllowed={() => isAllowed('payouts_settings')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_redirections'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateRedirectionsList}
        isAllowed={() => isAllowed('affiliate_redirections')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_redirections/new'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateRedirectionNew}
        isAllowed={() => isAllowed('affiliate_redirections')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_redirections/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateRedirectionEdit}
        isAllowed={() => isAllowed('affiliate_redirections')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/videos'
        component={isTrialPeriodEnd ? ExpiredTrial : FileLibrary}
        isAllowed={() => isAllowed('digitals')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/covers'
        component={isTrialPeriodEnd ? ExpiredTrial : FileLibrary}
        isAllowed={() => isAllowed('digitals')}
      />
      <ProtectedRoute exact path='/cabinet/fonts' component={FontsList} isAllowed={() => isAllowed('fonts')} />
      <ProtectedRoute
        exact
        path='/cabinet/digitals'
        component={isTrialPeriodEnd ? ExpiredTrial : FileLibrary}
        isAllowed={() => isAllowed('digitals')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/videos/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : VideoDetails}
        isAllowed={() => isAllowed('digitals')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/invoices'
        component={isTrialPeriodEnd ? ExpiredTrial : InvoicesReversalsList}
        isAllowed={() => isAllowed('invoices')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/invoices/new'
        component={isTrialPeriodEnd ? ExpiredTrial : InvoiceCreate}
        isAllowed={() => isAllowed('invoices')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/invoices/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : InvoicesItem}
        isAllowed={() => isAllowed('invoices')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/invoices/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : EditInvoice}
        isAllowed={() => isAllowed('invoices')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/dunning/:tab'
        component={DunningList}
        isAllowed={() => isAllowed(DUNNING_KEY)}
      />
      <ProtectedRoute
        exact
        path='/cabinet/claim_strategy'
        component={ClaimStrategySettings}
        isAllowed={() => isAllowed(DUNNING_KEY)}
      />
      <ProtectedRoute
        exact
        path='/cabinet/credit_memos'
        component={isTrialPeriodEnd ? ExpiredTrial : CreditMemosList}
        isAllowed={() => isAllowed('credit_memos')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payment_tickets/overview'
        component={isTrialPeriodEnd ? ExpiredTrial : PaymentTicketsOverview}
        isAllowed={() => isAllowed('tickets')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payment_tickets/details'
        component={isTrialPeriodEnd ? ExpiredTrial : PaymentTicketsDetails}
        isAllowed={() => isAllowed('tickets')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payment_tickets/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : PaymentTicketsItem}
        isAllowed={() => isAllowed('tickets')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/webhook_endpoints'
        component={isTrialPeriodEnd ? ExpiredTrial : WebhookEndpoints}
        isAllowed={() => isAllowed('webhook_endpoints')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/webhook_endpoints/new'
        component={isTrialPeriodEnd ? ExpiredTrial : WebhookEndpointsCreate}
        isAllowed={() => isAllowed('webhook_endpoints')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/webhook_endpoints/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : WebhookEndpointsEdit}
        isAllowed={() => isAllowed('webhook_endpoints')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/domains/custom_domain'
        component={CustomDomain}
        isAllowed={() => isAllowed('domains')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/domains/ablefy_subdomain'
        component={SubdomainPage}
        isAllowed={() => isAllowed('domains')}
      />
      <ProtectedRoute exact path='/cabinet/domains' component={DomainSettings} isAllowed={() => isAllowed('domains')} />
      <ProtectedRoute
        exact
        path='/cabinet/payment_page_templates'
        component={isTrialPeriodEnd ? ExpiredTrial : PaymentPageTemplatesList}
        isAllowed={() => isAllowed('payment_page_templates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payment_page_templates/new'
        component={isTrialPeriodEnd ? ExpiredTrial : PaymentPageTemplatesForm}
        isAllowed={() => isAllowed('payment_page_templates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payment_page_templates/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : PaymentPageTemplatesForm}
        isAllowed={() => isAllowed('payment_page_templates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/team_projects/:tab'
        component={isTrialPeriodEnd ? ExpiredTrial : TeamProjects}
        isAllowed={() => isAllowed('team_projects')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/team_projects/roles/:id/edit'
        component={TeamMemberRoleEdit}
        isAllowed={() => isAllowed('team_projects')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/orders'
        component={isTrialPeriodEnd ? ExpiredTrial : OrdersList}
        isAllowed={() => isAllowed('orders')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/orders/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : OrdersItem}
        isAllowed={() => isAllowed('orders')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/need_action_transfers'
        component={isTrialPeriodEnd ? ExpiredTrial : NeedActionTransfersList}
        isAllowed={() => isAllowed('transfers')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/viban_payments_tab/'
        component={isTrialPeriodEnd ? ExpiredTrial : VibanTransfersList}
        isAllowed={() => isAllowed('viban_payments')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/order_rates'
        component={isTrialPeriodEnd ? ExpiredTrial : OrderRatesList}
        isAllowed={() => isAllowed('order_rates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/order_rates/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : OrderRatesItem}
        isAllowed={() => isAllowed('order_rates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/courses/overview'
        component={isTrialPeriodEnd ? ExpiredTrial : CoursesOverview}
        isAllowed={() => isAllowed('courses')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/courses/lesson_statuses'
        component={isTrialPeriodEnd ? ExpiredTrial : LessonStatusesList}
        isAllowed={() => isAllowed('courses')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/courses/quiz_attempts'
        component={isTrialPeriodEnd ? ExpiredTrial : QuizList}
        isAllowed={() => isAllowed('courses')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/courses/quiz_attempts/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : QuizDetails}
        isAllowed={() => isAllowed('courses')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/courses/sessions'
        component={isTrialPeriodEnd ? ExpiredTrial : CourseSessionsList}
        isAllowed={() => isAllowed('courses')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/courses/sessions/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : CourseSessionsItem}
        isAllowed={() => isAllowed('courses')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payers'
        component={isTrialPeriodEnd ? ExpiredTrial : PayersList}
        isAllowed={() => isAllowed('payers')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/payers/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : PayersItem}
        isAllowed={() => isAllowed('payers')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/contact_form_leads'
        component={isTrialPeriodEnd ? ExpiredTrial : ContactFormLeadsList}
        isAllowed={() => isAllowed('contact_form_fields')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_programs'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliatePrograms}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_programs/new'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateProgramsForm}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_programs/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateProgramsForm}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_nodes'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateNodes}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_nodes/new'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateNodeCreate}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_statistics'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateProgramsStatistics}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute exact path='/cabinet/analysis' component={Analysis} isAllowed={() => isAllowed('analysis')} />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/business'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_business')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/subscriptions'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_subscriptions')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/conversion'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_conversion')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/funnels'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_funnels')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/bumps'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_bumps')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/customers'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_customers')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/product'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_product')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/marketing'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_marketing')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/sales_seller'
        component={Analysis3}
        isAllowed={() => isAllowed('metabase_analytics_sales')}
        defaultAccess
      />
      <ProtectedRoute
        exact
        path='/cabinet/analysis/:namespace'
        component={Analysis}
        isAllowed={() => isAllowed('analysis')}
      />
      <ProtectedRoute exact path='/cabinet/payouts' component={PayoutsList} isAllowed={() => isAllowed('payouts')} />
      <ProtectedRoute
        exact
        path='/cabinet/membership_themes'
        component={isTrialPeriodEnd ? ExpiredTrial : MembershipThemesList}
        isAllowed={() => isAllowed('membership_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/membership_themes/new'
        component={isTrialPeriodEnd ? ExpiredTrial : MembershipThemesForm}
        isAllowed={() => isAllowed('membership_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/membership_themes/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : MembershipThemesForm}
        isAllowed={() => isAllowed('membership_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/shop_themes'
        component={isTrialPeriodEnd ? ExpiredTrial : ShopThemesList}
        isAllowed={() => isAllowed('shop_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/shop_themes/new'
        component={isTrialPeriodEnd ? ExpiredTrial : ShopThemesForm}
        isAllowed={() => isAllowed('shop_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/shop_themes/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : ShopThemesForm}
        isAllowed={() => isAllowed('shop_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/course_themes'
        component={isTrialPeriodEnd ? ExpiredTrial : CourseThemesList}
        isAllowed={() => isAllowed('course_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/course_themes/new'
        component={isTrialPeriodEnd ? ExpiredTrial : CourseThemesForm}
        isAllowed={() => isAllowed('course_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/course_themes/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : CourseThemesForm}
        isAllowed={() => isAllowed('course_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/integrations'
        component={IntegrationSettings}
        isAllowed={() => isAllowed('integrations')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/email_automations'
        component={isTrialPeriodEnd ? ExpiredTrial : IntegrationsList}
        isAllowed={() => isAllowed('email_automations')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/email_automations/new'
        component={isTrialPeriodEnd ? ExpiredTrial : IntegrationsForm}
        isAllowed={() => isAllowed('email_automations')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/email_automations/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : IntegrationsForm}
        isAllowed={() => isAllowed('email_automations')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/pricing_plans'
        component={isTrialPeriodEnd ? ExpiredTrial : PricingPlansList}
        isAllowed={() => isAllowed('pricing_plans')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/pricing_plans/new'
        component={isTrialPeriodEnd ? ExpiredTrial : PricingPlansForm}
        isAllowed={() => isAllowed('pricing_plans')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/pricing_plans/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : PricingPlansForm}
        isAllowed={() => isAllowed('pricing_plans')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/profile/edit'
        component={ProfileForm}
        isAllowed={() => isAllowed('profile')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/bank_accounts'
        component={BankAccountsList}
        isAllowed={() => isAllowed('bank_accounts')}
      />
      <ProtectedRoute exact path='/cabinet/imprint' component={Imprint} isAllowed={() => isAllowed('profile')} />
      <ProtectedRoute exact path='/cabinet/terms' component={Terms} isAllowed={() => isAllowed('profile')} />
      <ProtectedRoute
        exact
        path='/cabinet/financial_reports'
        component={isTrialPeriodEnd ? ExpiredTrial : FinancialReportsList}
        isAllowed={() => isAllowed('financial_reports')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/invoice_summaries'
        component={isTrialPeriodEnd ? ExpiredTrial : InvoiceSummariesList}
        isAllowed={() => isAllowed('invoices')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/settings/payment_providers'
        component={PaymentMethods}
        isAllowed={() => isAllowed('payment_methods')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/settings/payment_settings'
        component={PaymentSettings}
        isAllowed={() => isAllowed('payment_methods')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/license_keys/overview'
        component={isTrialPeriodEnd ? ExpiredTrial : LicenseKeysOverview}
        isAllowed={() => isAllowed('license_keys')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/license_keys/details'
        component={isTrialPeriodEnd ? ExpiredTrial : LicenseKeysDetails}
        isAllowed={() => isAllowed('license_keys')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/certificates/overview'
        component={isTrialPeriodEnd ? ExpiredTrial : CertificatesOverview}
        isAllowed={() => isAllowed('certificates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/certificates/details'
        component={isTrialPeriodEnd ? ExpiredTrial : CertificatesDetails}
        isAllowed={() => isAllowed('certificates')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/membership_sessions/overview'
        component={isTrialPeriodEnd ? ExpiredTrial : MembershipSessionsOverview}
        isAllowed={() => isAllowed('membership_sessions')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/membership_sessions/access'
        component={isTrialPeriodEnd ? ExpiredTrial : MembershipSessionsAccess}
        isAllowed={() => isAllowed('membership_sessions')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/membership_sessions/:id'
        component={isTrialPeriodEnd ? ExpiredTrial : MembershipSessionsItem}
        isAllowed={() => isAllowed('membership_sessions')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/app_themes'
        component={isTrialPeriodEnd ? ExpiredTrial : AppThemesList}
        isAllowed={() => isAllowed('app_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/app_themes/new'
        component={isTrialPeriodEnd ? ExpiredTrial : AppThemesForm}
        isAllowed={() => isAllowed('app_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/app_themes/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : AppThemesForm}
        isAllowed={() => isAllowed('app_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/finish_registration'
        component={FinishRegistration}
        isAllowed={() => isAllowed('profile')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/invoice_setup'
        component={InvoiceSetup}
        isAllowed={() => isAllowed('profile')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/notific_types'
        component={NotificationSettings}
        isAllowed={() => isAllowed('profile')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/cancellation_terms/default'
        component={isTrialPeriodEnd ? ExpiredTrial : CancellationTermsList}
        isAllowed={() => isAllowed('cancellation_terms')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/cancellation_terms/custom'
        component={isTrialPeriodEnd ? ExpiredTrial : CancellationTermsList}
        isAllowed={() => isAllowed('cancellation_terms')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/cancellation_terms/new'
        component={isTrialPeriodEnd ? ExpiredTrial : CancellationTermsForm}
        isAllowed={() => isAllowed('cancellation_terms')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/cancellation_terms/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : CancellationTermsForm}
        isAllowed={() => isAllowed('cancellation_terms')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/mobile_app'
        component={MobileApp}
        isAllowed={() => isAllowed('mobile_app')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/iap_products'
        component={IapProducts}
        isAllowed={() => isAllowed('mobile_app')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/notifications'
        component={Notifications}
        isAllowed={() => isAllowed('mobile_app')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/email_configurations'
        component={EmailConfigurations}
        isAllowed={() => isAllowed('email_configurations')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/seller_custom_notifications/new'
        component={MobileAppNotificationsItem}
        isAllowed={() => isAllowed('mobile_app')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/seller_custom_notifications/:id/edit'
        component={MobileAppNotificationsItem}
        isAllowed={() => isAllowed('mobile_app')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_landings'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateLandingsList}
        isAllowed={() => isAllowed('affiliate_landings')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_landings/new'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateLandingsForm}
        isAllowed={() => isAllowed('affiliate_landings')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_landings/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : AffiliateLandingsForm}
        isAllowed={() => isAllowed('affiliate_landings')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/sharings'
        component={MultipleItemsSharingList}
        isAllowed={() => isAllowed('sharings')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/live_shopping_events/:id/edit'
        component={LiveShoppingForm}
        isAllowed={() => isAllowed('live_shopping_events')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/live_shopping_events/new'
        component={LiveShoppingForm}
        isAllowed={() => isAllowed('live_shopping_events')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/live_shopping_events'
        component={LiveShoppingList}
        isAllowed={() => isAllowed('live_shopping_events')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/static/badges'
        component={IframesResolver}
        isAllowed={() => isAllowed('badges')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/products/:id/preview'
        component={IframesResolver}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/embeddable_items'
        component={isTrialPeriodEnd ? ExpiredTrial : EmbeddableItems}
        isAllowed={() => isAllowed('embeddable_items')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/embeddable_items/new'
        component={isTrialPeriodEnd ? ExpiredTrial : EmbeddableItemsCreate}
        isAllowed={() => isAllowed('embeddable_items')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/embeddable_items/:id/edit'
        component={isTrialPeriodEnd ? ExpiredTrial : EmbeddableItemsEdit}
        isAllowed={() => isAllowed('embeddable_items')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/embeddable_items/:id/preview'
        component={isTrialPeriodEnd ? ExpiredTrial : EmbeddableItemsPreview}
        isAllowed={() => isAllowed('embeddable_items')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/shop_theme_preview/:shop_theme_id/theme_page/:theme_page_id'
        component={PreviewContent}
        isAllowed={() => isAllowed('shop_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/membership_theme_preview/:membership_theme_id/theme_page/:theme_page_id'
        component={PreviewContent}
        isAllowed={() => isAllowed('membership_themes')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/course_lesson_preview/:course_theme_id/theme_page/:lesson_content_id'
        component={PreviewContent}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/funnel_page_preview/:funnel_content_id'
        component={PreviewContent}
        isAllowed={() => isAllowed('products')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/communities'
        component={CommunitiesList}
        isAllowed={() => isAllowed('community_feature')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/communities_feed'
        component={CommunitiesFeedPosts}
        isAllowed={() => isAllowed('community_feature')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/communities_moderation'
        component={CommunitiesPostsModeration}
        isAllowed={() => isAllowed('community_feature')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/communities/:id/feed'
        component={CommunityFeed}
        isAllowed={() => isAllowed('community_feature')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/incentive_plan'
        component={IncentivePlanList}
        isAllowed={() => isAllowed('sales_teams')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/sales_team_member_invites'
        component={ManageSalesTeamMembersList}
        isAllowed={() => isAllowed('sales_teams')}
      />
      <ProtectedRoute exact path='/cabinet/deals' component={DealsList} isAllowed={() => isAllowed('sales_teams')} />
      <ProtectedRoute exact path='/cabinet/deals/:id' component={Deal} isAllowed={() => isAllowed('sales_teams')} />
      <ProtectedRoute
        exact
        path='/cabinet/sales_team'
        component={SalesTeam}
        isAllowed={() => isAllowed('sales_teams')}
      />

      <ProtectedRoute
        exact
        path='/cabinet/service_hub'
        component={ServiceHub}
        isAllowed={() => isAllowed('service_hub')}
      />

      <ProtectedRoute
        exact
        path='/cabinet/affiliate_marketplace'
        component={AffiliateMarketplace}
        isAllowed={() => isAllowed('affiliate_programs')}
      />
      <ProtectedRoute
        exact
        path='/cabinet/affiliate_marketplace/:id'
        component={AffiliateMarketplaceProductDetails}
        isAllowed={() => isAllowed('affiliate_programs')}
      />

      <Route exact path='/team_member*' component={ReloadPage} />
      <Route exact path='/404' component={Error404} />
      <Route exact path='*' component={PageNotFound} />
    </Switch>
  </Suspense>
)

@withRouter
class RoutesWithCancelation extends Component {
  componentDidMount() {
    const { history } = this.props

    history.listen((location) => {
      cancelPendingRequests(location)
    })
  }

  render() {
    const { isAllowed, isTrialPeriodEnd } = this.props

    return <Routes isAllowed={isAllowed} isTrialPeriodEnd={isTrialPeriodEnd} />
  }
}

export default RoutesWithCancelation
