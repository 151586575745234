import React, { useContext } from 'react'

import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings'

import type { ExperimentsStore } from 'shared/stores/experiments.store'
import { UserSessionContext } from './user-session-provider'

export const ExperimentsAndFeatureGatesProvider: React.FC<{
  children: React.ReactNode
  clientKey: string
  experimentsStore: ExperimentsStore
}> = ({ children, clientKey, experimentsStore }) => {
  const { userSessionId } = useContext(UserSessionContext)
  const environment = { tier: process.env.ENV }

  const user = {
    userID: userSessionId,
    statsigEnvironment: environment,
  }

  const { client } = useClientAsyncInit(clientKey, user)

  experimentsStore.setClient(client)

  return (
    <StatsigProvider sdkKey={clientKey} client={client} user={user}>
      {children}
    </StatsigProvider>
  )
}
